import React, { useEffect } from 'react';
import NavigationItem from '../lib/NavigationItem';
import AddInstance from './installations/instances/AddInstance';
import InstallationsOverview from './installations/InstallationsOverview';
import SegmentDetails from './installations/SegmentDetails';
import SendCommands from './installations/SendCommands';
import AddSegment from './installations/AddSegment';
import ConfigurationSets from './installations/configuration/ConfigurationSets';
import Diagnostics from './Diagnostics/Diagnostics';
import Packages from './Packages';
import Users from './Users';
import Unauthenticated from './Unauthenticated';
import Denied from './Denied';
import Error from './Error';
import Home from './Home';
import { Router, Link, useLocation, LinkGetProps } from '@reach/router';
import './Navigation.scss';
import EditInstance from './installations/instances/EditInstance';
import { ReactComponent as Logo } from '../sologic-logo-improved.svg';
import CopyInstance from './installations/instances/CopyInstance';

interface Props {
}

const linkGetPropsFn = (props: LinkGetProps) => {
    return {
        className: props.isCurrent ? 'is-active' : '',
    };
}
export const registry: NavigationItem[] = [
    new NavigationItem({ id: '/', label: 'Home', hash: '/', isDefault: true, isVisible: true, component: Home, }),

    new NavigationItem({
        id: 'installations',
        label: 'Installations',
        hash: 'installations',
        isVisible: true,
    }).addChild(
        new NavigationItem({
            id: 'segment',
            label: 'Segment Details',
            hash: 'segment',
        }).addChild(new NavigationItem({
            id: 'installation.segment.sendCommands',
            label: 'Send Commands',
            hash: 'commands',
        })).addChild(new NavigationItem({
            id: 'installations/segment/add',
            label: 'Add Segment',
            hash: 'segment/add',
        })).addChild(new NavigationItem({
            id: 'installation.segment.addInstance',
            label: 'Add Instance',
            hash: 'add-instance',
        })).addChild(new NavigationItem({
            id: 'installation.segment.editInstance',
            label: 'Edit Instance',
            hash: 'edit-instance',
        }))
    ).addChild(new NavigationItem({
        id: 'installations/configurationSets',
        label: 'Configuration Sets',
        hash: 'configuration',
        isVisible: true,
    })),

    new NavigationItem({ id: 'diagnostics', label: 'Diagnostics', hash: 'diagnostics', isVisible: true, }),
    new NavigationItem({ id: 'packages', label: 'Causelink Packages', hash: 'packages', isVisible: true, component: Packages }),
    new NavigationItem({ id: 'users', label: 'Users', hash: 'users', isVisible: true, component: Users }),

    new NavigationItem({ id: 'system.unauthenticated', label: 'Unauthenticated', hash: 'unauthenticated', component: Unauthenticated }),
    new NavigationItem({ id: 'system.denied', label: 'Denied', hash: 'denied', component: Denied }),
    new NavigationItem({ id: 'system.error', label: 'Error', hash: 'error', component: Error }),
];

const Navigation: React.FC<Props> = (props) => {
    const location = useLocation();

    useEffect(() => {
        let title = '';
        switch (location.pathname) {
            case '/installations':
                title = 'Installations';
                break;
            case '/installations/configurationSets':
                title = 'Configuration Sets';
                break;
            case '/diagnostics':
                title = 'Diagnostics';
                break;
            case '/users':
                title = 'Users';
                break;
            case '/packages':
                title = 'Packages';
                break;
            default: break;
        }
        if ((location.pathname.indexOf('/installations/segment/') > -1))
            title = 'Segment';

        if ((location.pathname.indexOf('/diagnostics/') > -1))
            title = 'Diagnostics';

        if (title) {
            document.title = title + ' | Causelink Manager';
        } else {
            document.title = 'Causelink Manager';
        }
    }, [location.pathname]);

    return (
        <React.Fragment>
            <ul id='manager-menu' className='menu-list has-background-white-bis'>
                <li className='logo is-flex'><Logo className='logo mb-3 p-4' /></li>
                {registry.filter(ni => ni.isVisible).map((ni) =>
                    <React.Fragment>
                        <Link to={ni.id} key={ni.hash}
                            getProps={linkGetPropsFn}
                        >{ni.label}</Link>
                        {ni.children.length > 0 && ni.children.find((c) => c.isVisible) &&
                            <li>
                                <ul>
                                    {ni.children.filter((c) => c.isVisible).map(nic =>
                                        <Link to={nic.id} key={nic.hash}
                                            getProps={linkGetPropsFn}
                                        >{nic.label}</Link>)
                                    }
                                </ul>
                            </li>
                        }
                        {ni.id === 'diagnostics' &&
                            <li id='diagnostics-child-list'>
                                <ul className='menu-list'>
                                    <li><Link to={'diagnostics/meta'} getProps={linkGetPropsFn}>Meta Information</Link></li>
                                    {/* {this.state.diagnostics?.hasSessionData &&
                                        <li className={this.state.panel === Panel.Session ? 'active' : ''}><Link to={'session'}>Session Log</Link></li>
                                    } */}
                                    <li><Link to={'diagnostics/session'} getProps={linkGetPropsFn}>Session Log</Link></li>
                                    {/* {this.state.diagnostics?.hasPersistentData
                                        ? <li className={this.state.panel === Panel.Replay ? 'active' : ''}><Link to={'replay'}>Replay Log</Link></li>
                                        : null} */}
                                    <li><Link to={'diagnostics/replay'} getProps={linkGetPropsFn}>Replay Log</Link></li>
                                    <li><Link to={'diagnostics/story'} getProps={linkGetPropsFn}>Story</Link></li>
                                </ul>
                            </li>
                        }
                    </React.Fragment>
                )}
            </ul>
            <Router>
                <Home path='/' />
                <InstallationsOverview path='installations' />
                <SegmentDetails path='installations/segment/:id' />
                <AddInstance path='installations/segment/:id/addInstance' />
                <EditInstance path='installations/segment/:segmentId/edit/:id' />
                <CopyInstance path='installations/segment/:segmentId/copy/:id' />
                <SendCommands path='installations/segment/:id/sendCommands' />
                <ConfigurationSets path='installations/configurationSets' />
                <AddSegment path='installations/segment/add' />
                <Diagnostics path='diagnostics/*' />
                <Packages path='packages' />
                <Users path='users' />

                <Unauthenticated path='unauthenticated' />
                <Denied path='denied' />
                <Error path='error' />
            </Router>
        </React.Fragment>
    );

}

export default Navigation;