import ApplicationUser from './models/ApplicationUser';
import Known from './Known';

type AuthenticationListener = () => void;

class AuthenticationManager {
    private listeners: AuthenticationListener[] = [];

    private _identity: ApplicationUser | undefined;
    public get identity(): ApplicationUser | undefined {
        return this._identity;
    }

    private _isAuthenticated: boolean;
    public get isAuthenticated(): boolean {
        return this._isAuthenticated;
    }

    public get isAdmin(): boolean {
        if (!this._isAuthenticated) {
            return false;
        }

        return (this._identity?.roleId === Known.Roles.AdminId) || (this._identity?.roleId === Known.Roles.ITId);
    }

    public get isManager(): boolean {
        if (!this._isAuthenticated) {
            return false;
        }

        return (this._identity?.roleId === Known.Roles.ManagerId) || this.isAdmin;
    }

    public constructor() {
        this._isAuthenticated = false;
        this.onAuthenticationCheck();
    }

    public addListener(listener: AuthenticationListener) {
        this.listeners.push(listener);
    }

    private onAuthenticationCheck() {
        // let hasChange = false;
        fetch('/account/self').then((response) => {
            if (response.ok) {
                response.json().then(identity => {

                    if (identity) {
                        // if (!this._isAuthenticated || (identity.id !== this._identity?.id)) {
                        //     hasChange = true;
                        // }

                        this._isAuthenticated = true;
                        this._identity = new ApplicationUser(identity);
                    }
                    else {
                        if (this._isAuthenticated || this._identity) {
                            // hasChange = true;
                        }

                        this._identity = undefined;
                        this._isAuthenticated = false;
                    }

                    // notify listeners
                    this.listeners.forEach(l => l());
                });
            }

            else {
                this._isAuthenticated = false;
                // hasChange = true;

                // notify listeners
                this.listeners.forEach(l => l());
            }

            setTimeout(this.onAuthenticationCheck.bind(this), 3600000); // check again in 1 hour
        }).catch(() => {
            this._isAuthenticated = false;

            // notify listeners
            this.listeners.forEach(l => l());
        });
    }
}

const authentication = new AuthenticationManager();
export default authentication;