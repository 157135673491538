import React from 'react';
import './DiagnosticInput.css'
import DiagnosticSchema from '../../lib/DiagnosticSchema';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { RouteComponentProps } from '@reach/router';

interface Props extends RouteComponentProps {
    onDiagnosticsAvailable(diagnostics: DiagnosticSchema): void;
}

interface State {
    raw: string;
    size: string;
    error: string | undefined;
}

function formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export default class DiagnosticInput extends React.Component<Props, State>{
    public constructor(props: Props) {
        super(props);

        this.state = { raw: '', size: formatBytes(0), error: undefined };
    }

    public render() {
        return (
            <div id="diagnosticInput" className='section container'>
                <header>
                    <h1 className='title'>Causelink Diagnostic Viewer</h1>
                </header>
                <div className='is-flex is-justify-content-center'>
                    <div className='file is-boxed is-primary'>
                        <label className='file-label'>
                            <input className='file-input' type="file" onChange={this.onFileSelect.bind(this)} />
                            <span className='file-cta'>
                                <span className='file-icon'>
                                    <FontAwesomeIcon icon={faUpload} />
                                </span>
                                <span className='file-label'>
                                    Choose a file...
                        </span>
                            </span>
                        </label>
                    </div>
                </div>
                <div className='subtitle has-text-centered'>or</div>
                <div>
                    <label>Raw Diagnostic Data: <textarea value={this.state.raw} onChange={(e) => this.onInputChanged(e.currentTarget.value)}></textarea></label>
                    {(this.state.error !== undefined)
                        ? <div className="error">{this.state.error}</div>
                        : ''}
                    <div>{this.state.size}</div>
                </div>
            </div>
        );
    }

    private onFileSelect(event: React.ChangeEvent<HTMLInputElement>) {
        var reader = new FileReader();

        reader.onload = (e) => {
            this.onInputChanged(reader.result!.toString());
        };

        reader.readAsText(event.target.files![0]);
    }

    private onInputChanged(str: string) {
        let json = {};

        this.setState({ error: undefined });

        try {
            json = JSON.parse(str);
            this.props.onDiagnosticsAvailable(DiagnosticSchema.from(json));
        } catch (ex) {
            this.setState({ error: ex.toString() });
            console.error(ex);
        }

        this.setState({ raw: str, size: formatBytes((new TextEncoder().encode(str)).length) });
    }
}