class Roles {
    public ITId = "d0cb50ce-c609-4788-b32f-a6b900061da9";
    public AdminId = "e6330960-eee9-db24-fddc-2f54dc4f8d56";
    public ManagerId = "8514e7c5-3a68-55d4-a9d3-30a7ee1242e8";
    public AnalystId = "c13759cf-da64-abb4-7de6-063cfd030c14";
    public SystemId = "8fc9dee8-09d5-4a3d-999d-ffac689b0fb2";
    public ParticipantId = "31327b35-3001-4180-af72-bdf974c4f931";
}

enum Response
{
    BadCredentials, RequiresAuthentication, ExpiredToken,
    ThatDoesntBelongToYou, RequiresElevatedPrivilege, LoginDenied,
    Locked,
    Continue = 127,
    Additional = 1024 // > 1024 used within packages
}

class Authentication
{
    public Response: Response = Response.Additional;
}

export default class Known {
    public static Roles: Roles = new Roles();
    public static Authentication: Authentication = new Authentication();
}

export const regions = new Map<string, string>([
    ['us-west-2', 'Oregon'],
    ['us-west-1', 'California'],
    ['us-east-1', 'North Virginia'],
    ["eu-west-1", 'Ireland'],
    ['us-east-2', 'Ohio']
]);

export const environments = new Map<string, string>([
    ['Development', 'Development'],
    ['QA', 'QA'],
    ["Staging", "Staging"],
    ['EDU', 'EDU'],
    ['CLI', 'CLI'],
    ['Production (Default)', 'Production (Default)'],
    ['Production (EU)', 'Production (EU)']
]);