import React from 'react';
import api from '../../lib/Api';
import authentication from '../../lib/AuthenticationManager';
import Installation from '../../lib/models/Installation';
import Segment from '../../lib/models/Segment';
import Input from '../../lib/ModelInput';
import { v4 as uuidv4 } from 'uuid';
import { regions, environments } from '../../lib/Known';
import SegmentPanel from './panels/SegmentPanel';
import { Link, RouteComponentProps } from '@reach/router';

import './InstallationsOverview.css'

class ViewState {
    showAddSegmentPanel: boolean = false;
    showAddInstallationPanel: boolean = false;
    showEditInstallationPanel: boolean = false;
    showEditSegmentPanel: boolean = false;
    showConfirmDialog: boolean = false;
}

interface Confirmation {
    label: string;
    resolve: () => void;
    reject: () => void;
}

interface Search {
    label: string;
    resolve: () => void;
    reject: () => void;
}

interface Props extends RouteComponentProps { }
interface State {
    installations: Installation[];
    segments: Segment[];
    viewState: ViewState;
    targetInstallation: Installation | undefined;
    targetSegment: Segment | undefined;
    confirm: Confirmation | undefined;
    search: Search | undefined;
    filteredSearch: Installation[];
}

export default class InstallationsOverview extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            installations: [],
            segments: [],
            viewState: new ViewState(),
            confirm: undefined,
            targetInstallation: undefined,
            targetSegment: undefined,
            search: undefined,
            filteredSearch: []
        };

        api.installations.list().then(installations => this.setState({ installations }));
        api.segments.list().then(segments => this.setState({ segments }));
    }

    public render() {

        return <div id="Installations" className='section container'>
            <h1 className='title'>Instances ({this.state.installations.length})</h1>

            {this.renderSearchForm()}

            {this.state.viewState.showConfirmDialog ? this.renderConfirmDialog() : ''}

            {this.state.viewState.showAddSegmentPanel ? this.renderSegmentPanel(true) : ''}
            {this.state.viewState.showEditSegmentPanel ? this.renderSegmentPanel(false) : ''}

            <div id="segments" className='columns is-multiline'>
                {this.renderPartition('Development')}
                {this.renderPartition('QA')}
                {this.renderPartition('Staging')}
                {this.renderPartition('EDU')}
                {this.renderPartition('CLI')}
                {this.renderPartition('Production (EU)')}
                {this.renderPartition('Production (Default)')}
            </div>

            <div id="operations">
                <div className='buttons is-right'>
                    <Link className='button' to="/installations/configurationSets">Configuration Sets</Link>

                    {authentication.isAdmin
                        ? <Link className='button is-primary' to={'/installations/segment/add'}>Add Segment</Link>
                        : ''}
                </div>
            </div>
        </div>;
    }

    private renderSearchForm = () => {
        const searchObj = { search: '' };
        return (
            <div className='pb-6'>
               <Input
                    type='text'
                    label=''
                    isReadOnly={false}
                    src={searchObj}
                    field='search'
                    debounceInterval={200}
                    onUpdate={(model) => {
                        this.filterSearchResults(model.current)
                    }}
                    placeholder='Search for an instance'
                />
                {this.state.filteredSearch.length !== 0 &&
                    <div className='is-relative'>
                        <div
                            className='menu box search-results'
                            id='search-dropdown'
                            role='menu'
                        >
                            <ul className='menu-list'>
                                {this.state.filteredSearch.map((item, key) => {
                                    return (
                                        <li className='dropdown-item'>
                                            <Link to={`/installations/segment/${item?.segmentId}`}>{item.name}</Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                }
            </div>
        )
    }

    private filterSearchResults = (value:string) => {
        const filteredSearch = this.state.installations.filter((installation) => {
            const match = installation?.name?.toLocaleLowerCase().indexOf(value) !== -1;
            if (match) {
                return installation;
            }
        })

        this.setState({
            filteredSearch: value.length ? filteredSearch : [],
        })
    }

    private renderPartition(label: string) {
        const partition = this.partitionByEnvironment(label);

        const howManyInstances = Array.from(partition.values()).map(v => v.length).reduce((pv, cv) => pv + cv, 0);

        return (
            <div className='column is-half'>
                <section className='card block'>
                    <header className='card-header'>
                        <div className='card-header-title'>{label}</div>
                        <div className='field is-grouped pt-3 pb-4 pl-3 pr-4'>
                            <div className='control'>
                                <div className='tags has-addons'>
                                    <div className='tag'>Segments</div>
                                    <div className='tag is-primary'>{partition.size}</div>
                                </div>
                            </div>
                            <div className='control'>
                                <div className='tags has-addons'>
                                    <div className='tag'>Instances</div>
                                    <div className='tag is-info'>{howManyInstances}</div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className='card-content'>
                        {Array.from(partition.keys()).length === 0 &&
                            <p>No segments found</p>
                        }
                        {Array.from(partition.keys()).map(sid => {
                            const segment = this.state.segments.find(s => s.id === sid)!;
                            const installations = partition.get(sid)!;

                            return <div key={sid} className="segment m-2 mb-5">
                                <div><h5 className='title is-6'>{segment.name}</h5></div>
                                <div className='subtitle is-6'>{installations.length} instance{installations.length === 1 ? '' : 's'} · <Link to={`/installations/segment/${segment.id}`}>View</Link>{authentication.isManager && <React.Fragment> · <Link to={`/installations/segment/${segment.id}/sendCommands`}>Send commands</Link></React.Fragment>}</div>
                            </div>;
                        })}
                    </div>
                </section>
            </div>
        );
    }

    private renderSegmentPanel(isAdd: boolean) {
        const segment = isAdd
            ? new Segment({ id: uuidv4(), region: regions.keys().next().value, environment: environments.keys().next().value })
            : this.state.targetSegment!;

        const hide = () => {
            const viewState = { ...this.state.viewState };
            viewState.showAddSegmentPanel = false;
            viewState.showEditSegmentPanel = false;

            this.setState({ viewState });
        };

        const update = () => {
            hide();

            if (isAdd) {
                api.segments.add(segment).then(() =>
                    api.segments.list().then(segments => this.setState({ segments })));
            } else {
                api.segments.update(segment);
            }
        };

        return <SegmentPanel isAdd={isAdd}
            segment={segment}
            onHide={hide}
            onUpdate={update} />;
    }

    private renderConfirmDialog() {
        const obj = { confirm: '' };

        const confirm = () => {
            if (obj.confirm !== 'confirm') {
                return;
            }

            const viewState = { ...this.state.viewState };
            viewState.showConfirmDialog = false;

            this.state.confirm!.resolve();
            this.setState({ viewState, confirm: undefined });
        };

        const cancel = () => {
            const viewState = { ...this.state.viewState };
            viewState.showConfirmDialog = false;

            this.state.confirm!.reject();
            this.setState({ viewState, confirm: undefined });
        };

        return <div className="confirm dialog">
            <h2>Confirm {this.state.confirm?.label}</h2>

            <p>The operation you are about to perform has real-world impacts. Type 'confirm' to continue:</p>

            <Input label="confirm" src={obj} field='confirm' />
            <button onClick={confirm}>Confirm</button>
            <button onClick={cancel}>Cancel</button>
        </div>;
    }

    private onDeleteSegment(segment: Segment, ev: React.MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();

        this.confirm(`delete segment ${segment.name}`).then(() => {
            api.segments.delete(segment).then(() => {
                api.segments.list().then((segments) => this.setState({ segments }));
            });
        });
    }

    private onEditInstallation(installation: Installation) {
        const state = { ...this.state };
        state.viewState.showEditInstallationPanel = true;
        state.targetInstallation = installation;

        this.setState(state);
    }

    private onEditSegment(segment: Segment) {
        const state = { ...this.state };
        state.viewState.showEditSegmentPanel = true;
        state.targetSegment = segment;

        this.setState(state);
    }

    private onDeleteInstallation(installation: Installation, ev: React.MouseEvent) {
        ev.preventDefault();
        ev.stopPropagation();

        this.confirm(`delete installation ${installation.name}`).then(() => {
            api.installations.delete(installation).then(() => {
                api.installations.list().then((installations) => this.setState({ installations }));
            });
        });
    }

    private confirm(label: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const viewState = { ...this.state.viewState };
            viewState.showConfirmDialog = true;

            this.setState({ viewState, confirm: { label, resolve, reject } });
        });
    }

    private partitionByEnvironment(env: string): Map<string, Installation[]> {
        const partition = new Map<string, Installation[]>();

        this.state.segments.sort((a, b) => a.name.localeCompare(b.name)).filter(s => s.environment === env).forEach((segment) =>
            partition.set(segment.id, this.state.installations.filter(i => i.segmentId === segment.id).sort((a, b) => (a.name || '').localeCompare(b.name || ''))));

        return partition;
    }

    private showAddInstallationPanel() {
        const viewState = { ...this.state.viewState };
        viewState.showAddInstallationPanel = true;

        this.setState({ viewState });
    }

    private showAddSegmentPanel() {
        const viewState = { ...this.state.viewState };
        viewState.showAddSegmentPanel = true;

        this.setState({ viewState });
    }
}