import React from 'react';
import authentication from './lib/AuthenticationManager';
import api from './lib/Api';
import { Location, Redirect, Router } from '@reach/router';
import Navigation from './components/Navigation';
import { ToastContainer, Zoom } from 'react-toastify';
import Unauthenticated from './components/Unauthenticated';

import './App.scss'
import 'react-toastify/dist/ReactToastify.css';

interface Props { }

interface State {
    isAuthenticated: boolean | undefined;
    showErrorDialog: boolean;
    errorDetails: string;
    version: string;
}

export default class App extends React.Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        // api.setErrorHandler(this.onAPIError.bind(this));

        this.state = {
            isAuthenticated: undefined,
            version: 'unknown',
            showErrorDialog: false,
            errorDetails: ''
        };

        api.version().then(version => this.setState({ version }));

        authentication.addListener(() => this.setState({ isAuthenticated: authentication.isAuthenticated }));

        const setInnerHeightVar = () => document.documentElement.style.setProperty('--innerHeight', window.innerHeight + 'px');
        setInnerHeightVar();
        window.onresize = setInnerHeightVar;
    }

    public render() {
        if (this.state.isAuthenticated) {
            return (
                <div id="App">
                    <Location>
                        {({ location }) => (
                            <>
                                <Navigation />
                                <footer className='manager-footer'>
                                    <div className=''>
                                        <h4>{authentication.identity?.displayName}</h4>
                                        <h5>{authentication.identity?.roleName}</h5>
                                        <div>version {this.state.version}</div>
                                    </div>
                                </footer>
                            </>
                        )}
                    </Location>
                    <ToastContainer
                        hideProgressBar={true}
                        transition={Zoom}
                        autoClose={10000}
                    />
                </div>
            );
        } else if (this.state.isAuthenticated === false) {
            return (
                <React.Fragment>
                    <Router>
                        <Unauthenticated path='unauthenticated' />
                    </Router>
                    <Redirect noThrow={true} to={'unauthenticated'} />
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    private renderContent() {
        if (this.state.showErrorDialog) {
            return <div id="error">
                <div>An error has occured</div>
                <div>{this.state.errorDetails}</div>
            </div>;
        }

    }

    private onAPIError(request: Request, response: Response) {
        this.setState({
            showErrorDialog: true,
            errorDetails: `${request.method} to ${request.url} failed with ${response.status} ${response.statusText}`
        });
    }
}
